export type TeamCardProps = {
  id: string;
  name: string;
  job: string[];
  href: string;
};

export const TeamCardDatas: TeamCardProps[] = [
  {
    id: 'team_01',
    name: 'Jung Hun',
    job: ['Head of Director'],
    href: 'https://www.linkedin.com/in/jhmuseum/',
  },
  {
    id: 'team_03',
    name: 'David Park',
    job: ['Chief Strategy Officer'],
    href: 'https://www.linkedin.com/in/davidparkcw/',
  },
  {
    id: 'team_06',
    name: 'Jeong Hwan',
    job: ['Director of R&D'],
    href: 'https://www.linkedin.com/in/carameleon/',
  },
  {
    id: 'team_23',
    name: 'Kyu Hong',
    job: ['Chief Financial Officer'],
    href: 'https://www.linkedin.com/in/kyuhong-shim/',
  },
  {
    id: 'team_02',
    name: 'Yong Joo',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/yongjoojung/',
  },
  {
    id: 'team_04',
    name: 'Jay Park',
    job: ['Director of Operation'],
    href: 'https://www.linkedin.com/in/jay-park-a7bba414b/',
  },
  {
    id: 'team_14',
    name: 'Sung Rak',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/sungrak-jung-534609b7/',
  },
  {
    id: 'team_15',
    name: 'In Seok',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/inseok-seo/',
  },
  {
    id: 'team_20',
    name: 'Hyun Woo',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/hyunwoo-han-504088235/',
  },
  {
    id: 'team_18',
    name: 'Jay Lee',
    job: ['Researcher'],
    href: 'https://www.linkedin.com/in/lee-jeongeun-1a622b221/',
  },
  {
    id: 'team_11',
    name: 'Hyuk Joon',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/hyukjoon-kwon-85b8a321a/',
  },
  {
    id: 'team_19',
    name: 'Jong Hyun',
    job: ['UX Designer / 3D Artist'],
    href: 'https://www.linkedin.com/in/lee-jonghyun-248ba3234/',
  },
  {
    id: 'team_21',
    name: 'Hee Je',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/heeje-kim-371072237/',
  },
  {
    id: 'team_22',
    name: 'Jeong Seup',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/jeongseup-son-901624237/',
  },
  {
    id: 'team_25',
    name: 'Sang Yeong',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/sangyeong-mun-56a1b811a/',
  },
  {
    id: 'team_26',
    name: 'Yeoung Il',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/bosees012/',
  },
  {
    id: 'team_28',
    name: 'Si Hun',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/%EC%8B%9C%ED%97%8C-%EC%95%88-567597261/',
  },
  {
    id: 'team_31',
    name: 'So Hee',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/소희-이-47b77725b/',
  },
  {
    id: 'team_35',
    name: 'Jun Yup',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/junyuphong/',
  },
  {
    id: 'team_36',
    name: 'Da Jin',
    job: ['HR Manager'],
    href: 'https://www.linkedin.com/in/dajin-seo-6a7591282/',
  },
  {
    id: 'team_37',
    name: 'Farkhod Abdukodirov',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/farkhod-abdukodirov-32ab32150/',
  },
  {
    id: 'team_39',
    name: 'Minki Cho',
    job: ['Software Engineer'],
    href: 'https://www.linkedin.com/in/%EB%AF%BC%EA%B8%B0-%EC%A1%B0-447187242/',
  },
  {
    id: 'team_40',
    name: 'Yoonseol Lee',
    job: ['UI / UX Designer'],
    href: 'https://www.linkedin.com/in/yoonseol-lee-464b1b310/',
  },
];
